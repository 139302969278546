const throttle = require('throttle-debounce/throttle')
const channel = 'studiototo'
const PUBNUB_INSTANCE = PUBNUB.init({
  publish_key: 'pub-c-d5242fb4-e4cb-4f8f-81ce-8a5679950e09',
  subscribe_key: 'sub-c-b97c826c-01b4-11e7-a8c8-02ee2ddab7fe'
})
const ID = 'xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
})

const config = {
  drawPixelDelay: 20,
  pushInterval: 500,
  pixelSize: 2,
  remoteDrawInterval: 20,
  remoteDrawCount: 1,
  persistentSaveInterval: 10 * 1000
}

let canvas, context, background
let persistentCache = []
let uploadCache = []
let downloadCache = {}
let isDrawActive = false

window.onload = () => {

  let container = document.getElementById('drawing')
  container.scrollLeft = (container.scrollWidth - container.clientWidth) / 2
  
  document.body.classList.remove('loading')
  document.getElementById('canvas__predrawn').src = 'predrawn.png?' + ID

  createCanvas()
  createContext()
  canvas.onclick = clickHandler
  canvas.onmousedown = () => isDrawActive = true
  canvas.onmouseup = () => isDrawActive = false
  document.onmouseout = () => isDrawActive = false
  canvas.oncontextmenu = clickHandler
  canvas.onmousemove = moveHandler
  setInterval(pushChanges, config.pushInterval)
  setInterval(drawRemote, config.remoteDrawInterval)
  setInterval(pushPersistentCache, config.persistentSaveInterval)
  window.onbeforeunload = pushPersistentCache
}

function createCanvas() {
  background = document.getElementById('canvas__background')
  canvas = document.createElement('canvas')
  canvas.classList.add('canvas__foreground')
  canvas.width = background.getBoundingClientRect().width
  canvas.height = background.getBoundingClientRect().height
  background.parentNode.append(canvas)
}

function createContext() {
  context = canvas.getContext('2d')
  context.fillStyle = 'rgba(2, 11, 38, 0.6)'
}

function drawPixel(x, y) {
  context.fillRect(x, y, config.pixelSize, config.pixelSize)
  uploadCache.push([x, y])
  persistentCache.push([x, y])
}
const throttledDrawPixel = throttle(config.drawPixelDelay, drawPixel)

function drawRemotePixel(x, y) {
  context.fillRect(x, y, config.pixelSize, config.pixelSize)
}

function clickHandler(event) {
  event.stopPropagation()
  event.preventDefault()
  return false
}

function moveHandler(event) {
  if(!isDrawActive) {
    return
  }
  throttledDrawPixel(event.offsetX, event.offsetY)
}

function pushChanges() {
  if(!uploadCache.length) {
    return
  }
  PUBNUB_INSTANCE.publish({
    channel: channel,
    message: {
      pixels: uploadCache,
      id: ID
    }
  })
  uploadCache = []
}

function pullChanges(message) {
  if(!message || message.id === ID) {
    return
  }
  if(!downloadCache[message.id]) {
    downloadCache[message.id] = []
  }
  Array.prototype.push.apply(downloadCache[message.id], message.pixels)
}

function drawRemote() {
  for(let user in downloadCache) {
    if(!downloadCache.hasOwnProperty(user)) {
      continue;
    }
    for(let i = 0; i < config.remoteDrawCount; i++) {
      if(!downloadCache[user].length) {
        return
      }
      let pixel = downloadCache[user].shift()
      drawRemotePixel(pixel[0], pixel[1])
    }
  }
}

function pushPersistentCache() {
  if(!persistentCache.length) {
    return
  }

  let payload = 'pixels='
  for(let pixel of persistentCache) {
    payload += pixel[0] + ':' + pixel[1] + ';'
  }

  let xhr = new XMLHttpRequest()
  xhr.open('POST', 'put.php', true)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.send(payload)
  persistentCache = []
}

window.mypush = pushPersistentCache

PUBNUB_INSTANCE.subscribe({
  channel: channel,
  callback: pullChanges,
})

document.getElementById('downloadlink').addEventListener('click', function() {
  let saveCanvas = document.createElement('canvas')
  saveCanvas.width = canvas.width
  saveCanvas.height = canvas.height
  let saveContext = saveCanvas.getContext('2d')
  saveContext.drawImage(document.getElementById('canvas__background'), 0, 0)

  saveContext.filter = 'blur(0.5px)'
  saveContext.globalCompositeOperation = 'multiply'
  saveContext.globalAlpha = 0.65

  if(document.getElementById('canvas__predrawn').naturalWidth) {
    saveContext.drawImage(document.getElementById('canvas__predrawn'), 0, 0)
  }

  saveContext.globalAlpha = 0.9

  saveContext.drawImage(canvas, 0, 0)

  this.download = 'tattoo_' + Date.now() + '.jpg'
  this.href = saveCanvas.toDataURL('image/jpeg')
}, false)
